<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-24 20:55:55
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-18 11:00:14
-->
<template>
  <div class="news">
    <div class="top">
      <div class="icon"><img src="@/assets/images/new.png" alt=""></div>
      <div class="title">招生动态</div>
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index" v-if="index <= 5" @click="goDetail(item)">
        <div class="samall">
          <span>{{item.post_title}}</span>
        </div>
        <div class="time">{{item.published_time}}</div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogTableVisible" append-to-body width="400px" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" custom-class="diaAtrel">
      <div class="contents">VIP用户才可以查看哦</div>
      <div class="footers">
        <div class="item q" @click="(dialogTableVisible=false)">取消</div>
        <div class="item c" @click="$router.push({name: 'advisory'})">去咨询</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import http from '@/util/http'
export default {
  data() {
    return {
      list: [],
      dialogTableVisible: false
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      var params = {
        page: 1,
        c_id: 2
      }
      var { data } = await http.post('/api/portal/getList.html', params);
      this.list = data.data;
    },
    goDetail(item) {
      var userInfo = this.$store.state.userInfo;
      if(this.active == 2){
        if (!this.$store.getters.uniqid) {
          this.$root.Bus.$emit('showLogin')
          return
        }
        this.$router.push('/question/' + item.id)
      }else{
        if(item.is_vip == 1){
          if (!this.$store.getters.uniqid) {
            this.$root.Bus.$emit('showLogin')
            return
          }else{
            if(userInfo.is_vip == 0){
              this.dialogTableVisible = true;
              return;
            }else{
              if(this.type == 'down'){
                window.open('打开下载地址', '_blank');
                return;
              }
              window.open('#/newDetail?id='+item.id, '_blank');
            }
          }
        }else{
          if(this.type == 'down'){
            window.open('打开下载地址', '_blank');
            return;
          }
          window.open('#/newDetail?id='+item.id, '_blank');
        }
      }
      // this.$router.resolve({
      //   name: 'newDetail',
      //   query: {
      //     id: item.id
      //   }
      // })
    },
  },
}
</script>

<style lang="scss" scoped>
.news {
  flex: 1;
  padding-left: 30px;

  .list {
    .item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(232, 232, 232, 1);
      cursor: pointer;
      &:first-child {
        border-top: 1px solid rgba(232, 232, 232, 1);
      }

      .samall {
        line-height: 60px;
        width: 430px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
          font-weight: 400;
          font-size: 16px;
        }
      }

      .time {
        line-height: 60px;
        font-weight: 400;
        font-size: 16px;
        color: rgba(153, 153, 153, 1);
      }
    }
  }

  .top {
    display: flex;
    width: 100%;
    padding-bottom: 15px;

    .title {
      font-size: 24px;
      font-weight: 500;
      padding-left: 8px;
    }

    .icon {
      width: 24px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>