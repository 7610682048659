<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-24 20:34:12
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-29 10:38:56
-->
<template>
  <div class="swiper">
    <div class="banner">
      <el-carousel :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <div class="img" :style="`background-image:url(${item.thumbnail})`" @click="goDetail(item)"></div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="gg">
      <div class="item">
        <div class="icon"><img src="@/assets/images/qq.png" alt=""></div>
        <div class="font">QQ：429832014</div>
        <div class="font">（饼干老师）</div>
      </div>
      <div class="item">
        <div class="icon"><img src="@/assets/images/weixin.png" alt=""></div>
        <div class="font">微信：18211059862</div>
        <div class="font">（饼干老师）</div>
      </div>
      <div class="item">
        <div class="icon"><img src="@/assets/images/bilbil.png" alt=""></div>
        <div class="font">B站：饼干讲金专</div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/util/http'
export default {
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      var params = {
        position: 2
      }
      var { data } = await http.post('/api/slide/getList.html', params);
      this.list = data;
    },
    goDetail(row){
      if(row.redirect_type == 0) return;
      if(row.redirect_type == 1) return window.location.href = row.redirect_obj;
      if(row.redirect_type == 2) return window.open('#/newDetail?id='+row.redirect_obj, '_blank');
      if(row.redirect_type == 3) return window.open('#/liveDetail?id='+row.redirect_obj, '_blank');
      if(row.redirect_type == 4) return window.open('#/question/'+row.redirect_obj, '_blank');
    }
  },
}
</script>

<style lang="scss" scoped>
.swiper {
  .gg {
    display: flex;
    align-items: center;
    width: 100%;
    height: 130px;
    margin-top: 11px;
    background-color: rgba(247, 247, 247, 1);

    .item {
      flex: 1;

      .font {
        text-align: center;
      }

      .icon {
        width: 120px;
        margin: 0 auto;

        img {
          width: 100%;
        }
      }
    }
  }

  .banner {
    width: 560px;
    height: 272px;

    ::v-deep {
      .el-carousel__indicators--horizontal {
        padding: 0 10px;

        .el-carousel__indicator--horizontal {
          padding: 0 5px;

          &.is-active button {
            background-color: rgba(255, 255, 255, 1);
          }

          .el-carousel__button {
            background-color: rgba(255, 255, 255, .4);
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
        }
      }

      .el-carousel__arrow {
        width: 32px;
        height: 40px;
        border-radius: 0;
        background-color: rgba(0, 0, 0, .4);

        &.el-carousel__arrow--left {
          left: 0;
        }

        &.el-carousel__arrow--right {
          right: 0;
        }
      }

      .el-carousel__container {
        height: 272px;
      }
    }

    .img {
      width: 100%;
      height: 272px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
}
</style>