<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-24 18:05:16
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2022-12-24 21:23:52
-->
<template>
  <body-layer>
    <div class="base">
      <div class="hd">
        <swiper></swiper>
        <news></news>
      </div>
      <live></live>
      <links></links>
    </div>
  </body-layer>
</template>

<script>
import bodyLayer from '@/components/bodyLayer.vue';
import swiper from '@/components/indexs/swiper.vue';
import news from '@/components/indexs/news.vue';
import live from '@/components/indexs/live.vue';
import links from '@/components/links.vue';
export default {
  components: { bodyLayer, swiper, news, live, links },
}
</script>

<style lang="scss" scoped>
.base {
  width: 100%;
  background-color: #fff;
  padding: 30px;
  position: relative;
  .hd {
    display: flex;
    align-items: flex-start;
  }
}
</style>