<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-24 21:20:22
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-03 23:08:39
-->
<template>
  <div class="bd">
    <div class="top">
      <div class="left">
        <div class="icon"><img src="@/assets/images/live.png" alt=""></div>
        <div class="title">直播公开课</div>
      </div>
      <div class="right" @click="$router.push({name: 'live'})">全部课程 <i class="el-icon-arrow-right"></i></div>
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="image" :style="`background-image:url(${item.thumbnail})`">
        </div>
        <div class="context">
          <div class="title">
            <span>{{item.post_title}}</span>
          </div>
          <div class="dest">
            <span>{{item.post_excerpt}}</span>
          </div>
          <div class="tag" v-if="item.post_keywords">
            <i v-for="(d, idx) in item.post_keywords.split(',')" :key="idx">{{d}}</i>
          </div>
          <div class="goBtn" @click="$router.push({name: 'liveDetail', query: {id: item.id}})">去看看</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/util/http'
export default{
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      if(!this.$store.getters.uniqid) return;
      var params = {
        home: 1,
        uniqid: this.$store.getters.uniqid,
        page: 1
      }
      var { data } = await http.post('/api/course/getList', params);
      this.list = data.data;
      console.log(data.data);
    },
  },
}
</script>

<style lang="scss" scoped>
.bd {
  padding-top: 60px;

  .list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .item {
      width: 50%;
      display: flex;
      margin-top: 30px;

      .context {
        padding-left: 15px;
        width: 100%;
        height: 120px;
        position: relative;

        .goBtn {
          width: 80px;
          height: 32px;
          line-height: 32px;
          border-radius: 32px;
          font-size: 16px;
          color: #FFF;
          background: rgba(255, 106, 41, 1);
          text-align: center;
          position: absolute;
          left: 15px;
          bottom: 0;
          cursor: pointer;
        }

        .tag {
          display: flex;

          i {
            height: 20px;
            line-height: 20px;
            padding: 0 8px;
            font-size: 12px;
            color: rgba(102, 102, 102, 1);
            background: rgba(242, 242, 242, 1);
            display: inline-block;
            margin-right: 5px;
            font-style: normal;
          }
        }

        .dest {
          width: 360px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 5px 0;
          line-height: 1;
          span {
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
          }
        }

        .title {
          width: 360px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 1;
          span {
            color: rgba(0, 0, 0, 1);
            font-size: 16px;
          }
        }
      }

      .image {
        width: 180px;
        height: 120px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .right {
      color: rgba(153, 153, 153, 1);
      font-size: 14px;
      cursor: pointer;
    }

    .left {
      display: flex;

      .title {
        font-size: 24px;
        font-weight: 500;
        padding-left: 8px;
      }

      .icon {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>